.participantContainer {
  display: flex;
  gap: 0.8rem;
  padding: 0.8rem 1rem;
  background-color: var(--background-color1);
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px 0px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all 50ms ease-in-out;
}

.participantContainer:hover {
  background-color: rgba(var(--accent-color1), 0.12);
  animation: jump 200ms forwards;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0);
  }
}
