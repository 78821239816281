/*
 Template Name: Veltrix - Responsive Bootstrap 4 Admin Dashboard
 Author: Themesbrand
 Website: www.themesbrand.com
 File: Main Css File
*/
/* ============
TABLES OF CSS
---------------

01. General
02. Bootstrap-custom
03. Helper
04. Waves Effect
05. Menu
06. Buttons
07. Cards
08. Email
09. Summernote
10. Alerts
11. Demo Only
12. Pagination
13. Progress
14. Popover - Tooltips
15. Sweet Alerts
16. Tabs
17. Form-elements
18. Form-validation
19. Form-advanced
20. Form-editors
21. Form-upload
22. Calendar
23. Tables
24. Maps
25. Range-slider
26. Session-timeout
27. Charts
28. Widgets
29. TImeline
30. Directory
31. Extra Pages
32. gallery
33. Invoice
34. Account Pages
35. Responsive


*/
@import "variables";
/* ======
01. General
   ====== */
@import "general";
/* ======
02. Bootstrap-custom
   ====== */
@import "bootstrap-custom";
/* ======
03. Helper
   ====== */
@import "helper";
/* ======
04. Waves Effect
   ====== */
@import "waves";
/* ======
05. Menu
   ====== */
@import "menu";
/* ======
06. Buttons
   ====== */
@import "buttons";
/* ======
07. Cards
   ====== */
@import "cards";
/* ======
08. Email
   ====== */
@import "email";
/* ======
09. Summernote
   ====== */
@import "summernote";
/* ======
10. Alerts
   ====== */
@import "alerts";
/* ======
11. Demo Only
   ====== */
@import "demo-only";
/* ======
12. Pagination
   ====== */
@import "pagination";
/* ======
13. Progress
   ====== */
@import "progress";
/* ======
14. Popover - Tooltips
   ====== */
@import "popover-tooltips";
/* ======
15. Sweet Alerts
   ====== */
@import "sweet-alert";
/* ======
16. Tabs
   ====== */
@import "tabs";
/* ======
17. Form-elements
   ====== */
@import "form-elements";
/* ======
18. Form-validation
   ====== */
@import "form-validation";
/* ======
19. Form-advanced
   ====== */
@import "form-advanced";
/* ======
20. Form-editors
   ====== */
@import "form-editor";
/* ======
21. Form-upload
   ====== */
@import "form-upload";
/* ======
22. Form-Wizard
   ====== */
@import "form-wizard";
/* ======
22. Calendar
   ====== */
@import "calendar";
/* ======
23. Tables
   ====== */
@import "tables";
/* ======
24. Maps
   ====== */
@import "maps";
/* ======
25. Range-slider
   ====== */
@import "range-slider";
/* ======
26. Session-timeout
   ====== */
@import "session-timeout";
/* ======
27. Charts
   ====== */
@import "charts";
/* ======
28. Widgets
   ====== */
@import "widgets";
/* ======
29. TImeline
   ====== */
@import "timeline";
/* ======
30. Directory
   ====== */
@import "directory";
/* ======
31. Extra Pages
   ====== */
@import "extra-pages";
/* ======
32. gallery
   ====== */
@import "gallery";
/* ======
33. Invoice
   ====== */
@import "invoice";
/* ======
34. Account Pages
   ====== */
@import "account-pages";
/* ======
35. Responsive
   ====== */
@import "responsive";